<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.assetSn" class="input" placeholder="资产码" clearable></el-input>
      <el-button type="primary" @click="search" :loading="loading">查询</el-button>
    </el-row>

    <el-table :data="issueList" border stripe>
      <el-table-column align="center" prop="assetSn" label="资产码" width="200"></el-table-column>
      <el-table-column align="center" prop="stationName" label="工站" width="120"></el-table-column>
      <el-table-column align="center" prop="issueRemark" label="异常描述" width="500"></el-table-column>
      <el-table-column align="center" prop="finish" label="是否完成" width="80" :formatter="isFormatter"></el-table-column>
      <el-table-column align="center" prop="dealRemark" label="处理结果" width="500"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人" width="80"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="150"></el-table-column>
      <el-table-column align="center" prop="dealUserName" label="处理人" width="80"></el-table-column>
      <el-table-column align="center" prop="dealTime" label="处理时间" width="150"></el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>

  </el-card>
</template>

<script>
export default {
  name: "IssueList",
  data() {
    return {
      queryInfo: {
        assetSn: '',
      },
      formData: {
        finish: false,
        dealRemark: ''
      },
      total: 0,
      pageSize: 10,
      loading: false,
      issueList: []
    }
  },
  created() {
    this.search()
  },
  methods: {
    search() {
      this.loading = true
      this.$axios.post('busItemIssue/queryAllIssuePage', this.queryInfo).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.issueList = res.data.records;
        this.pageSize = res.data.size
        this.total = res.data.total
      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });

    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    isFormatter(row, column, cellValue) {
      if (cellValue == true) {
        return '是';
      }
      if (cellValue == false) {
        return '否';
      }
      if (cellValue == null || cellValue == '') {
        return '';
      }
    },
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}
</style>